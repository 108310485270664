import config from "../../assets/js/conf/config";
import request from "../../assets/js/utils/request";
/**
 * 我的钱包
 */
export function moneyData(data){//获取钱包金额信息
	return request.post(config.baseApi+"/business/basinessmoneyinfo",data)
}
export function paymentCode(data){//获取支付二维码
	return request.post(config.baseApi+"/business/paymentcode",data)
}
export function checkRecharge(data){//查询充值情况
	return request.post(config.baseApi+"/business/basinessrecharge",data)
}
export function tixianInfo(data){//获取提现信息
	return request.post(config.baseApi+"/business/basinesstixianinfo",data)
}
export function tixianData(data){//发起提现
	return request.post(config.baseApi+"/business/basinesstixian",data)
}
export function walletData(data){//钱包记录流水
	return request.post(config.baseApi+"/business/basinessjineflow",data)
}

/**
 * 上架策略
 */
export function celveData(data){//获取上架策略数据
	return request.post(config.baseApi+"/business/strategylist",data)
}
export function saveCelve(data){//保存上架策略
	return request.post(config.baseApi+"/business/editstrategy",data)
}
export function delCelve(data){//删除上架策略
	return request.post(config.baseApi+"/business/delstrategy",data)
}

/**
 * 个人设置
 */
export function setAvatar(data){//更换头像
	return request.post(config.baseApi+"/business/basinessediticon",data)
}
export function changePhone(data){//换绑手机
	return request.post(config.baseApi+"/business/basinesseditmobile",data)
}
export function changePwd(data){//更改密码
	return request.post(config.baseApi+"/business/basinesseditpassword",data)
}
export function changeRemind(data){//接收公众号提醒
	return request.post(config.baseApi+"/business/basinesssetwxremind",data)
}
export function changeDecrypt(data){//开启自动解密
	return request.post(config.baseApi+"/business/basinesssetdecrypt",data)
}
export function changeAutoPurchase(data){//开启自动采购
	return request.post(config.baseApi+"/business/changeAutoPurchase",data)
}

export function decryptLog(data){//解密日志
	return request.get(config.baseApi+"/business/User/decryptLog",data)
}
export function changeAutoPrint(data){//开启自动打单
	return request.post(config.baseApi+"/business/basinesssetautoprintorder",data)
}

/**
 * 推广中心
 */
export function extendUrl(data){//获取推广中心链接
	return request.post(config.baseApi+"/business/basinesstgurl",data)
}
export function extendList(data){//获取推广中心合作列表
	return request.post(config.baseApi+"/business/basinesstglist",data)
}

/**
 * 我的抖店 
 */
export function ddShopList(data){//获取我的抖店列表
	return request.get(config.baseApi+"/business/DdShop/list",data)
}
export function ddShopDel(data){//删除抖店
	return request.post(config.baseApi+"/business/DdShop/del",data)
}

/**
 * 我的商品
 */
export function goodsFilterData(data){//获取商品筛选数据
	return request.get(config.baseApi+"/business/DdProduct/filter_data",data)
}
export function goodsList(data){//获取商品列表
	return request.get(config.baseApi+"/business/DdProduct/list",data)
}

export function goodsListSecond(data){//获取二次谈价商品列表
	return request.get(config.baseApi+"/business/DdProduct/listSecond",data)
}

export function saleConfigSave(data){//二次谈价销量配置保存
	return request.post(config.baseApi+"/business/DdProduct/saleConfigSave",data)
}
export function batOnSale(data){//批量上架商品
	return request.post(config.baseApi+"/business/DdProduct/bat_on_sale",data)
}
export function batOffSale(data){//批量下架商品
	return request.post(config.baseApi+"/business/DdProduct/bat_off_sale",data)
}
export function batProductEdit(data){//商品批量改价
	return request.post(config.baseApi+"/business/DdProduct/bat_product_edit",data)
}
export function productDel(data){//删除商品
	return request.post(config.baseApi+"/business/DdProduct/del",data)
}
export function batProductDel(data){//删除商品
	return request.post(config.baseApi+"/business/DdProduct/bat_del",data)
}
export function productEditUrl(data){//获取url
	return request.post(config.baseApi+"/business/Edit/getProductEditUrl",data)
}

export function shopEditUrl(data){//获取url
	return request.post(config.baseApi+"/business/Edit/getShopEditUrl",data)
}

/**
 * 商品关联
 */
export function goodsRelativeInfoData(data){//获取关联商品信息
	return request.get(config.baseApi+"/business/DdProduct/relativeInfo",data)
}
export function checkoutSkuRelative(data){//关联商品检出变动订单数、金额
	return request.post(config.baseApi+"/business/DdProduct/checkoutSkuRelative",data)
}
export function goodsConfigData(data){//获取关联商品规格
	return request.get(config.baseApi+"/business/DdProduct/productSku",data)
}
export function submitRelative(data){//获取关联商品规格
	return request.post(config.baseApi+"/business/DdProduct/subSkuRelative",data)
}
export function similarGoodsData(data){//搜索同款商品
	return request.get(config.baseApi+"/business/DdProduct/searchProduct",data)
}
export function syncProduct(data){//一键同步商品
	return request.post(config.baseApi+"/business/DdProduct/syncProduct",data)
}
export function goodsSkuChangeLog(data){//一键同步商品
	return request.post(config.baseApi+"/business/DdProduct/skuChangeLog",data)
}

/**
 * 订单管理
 */
export function orderManageList(data){//获取我的订单列表
	return request.get(config.baseApi+"/business/OrderManage/list",data)
}

export function orderManageCutList(data){//获取拦截订单列表
	return request.get(config.baseApi+"/business/OrderManage/listCut",data)
}

export function orderManageAddressInfo(data){//查看收货人信息
	return request.get(config.baseApi+"/business/OrderManage/addressInfo",data)
}
export function orderManageShopList(data){//商家店铺列表
	return request.get(config.baseApi+"/business/OrderManage/shopList",data)
}
export function editSyncOrderQueue(data){//商家同步历史订单
	return request.post(config.baseApi+"/business/OrderManage/editSyncOrderQueue",data)
}
export function orderManageDetail(data){//订单详情
	return request.get(config.baseApi+"/business/OrderManage/detail",data)
}
export function orderManageFilterData(data){//获取订单筛选数据
	return request.get(config.baseApi+"/business/OrderManage/filterData",data)
}
export function orderManageBatPurchase(data){//手动批量采购
	return request.get(config.baseApi+"/business/OrderManage/batPurchase",data)
}
export function orderManageBatAbnormal(data){//批量标记异常
	return request.post(config.baseApi+"/business/OrderManage/batAbnormal",data)
}
export function orderManageBatIntercept(data){//批量设置拦截
	return request.post(config.baseApi+"/business/OrderManage/batIntercept",data)
}
export function orderManageChangeSku(data){//关联商品
	return request.post(config.baseApi+"/business/OrderManage/changeOrderSku",data)
}
export function orderManageCheckoutChangeSku(data){//修改关联商品计算变动订单数、金额
	return request.post(config.baseApi+"/business/OrderManage/checkoutChangeSku",data)
}
export function orderManageRemarkInfo(data){//订单标记备注信息
	return request.get(config.baseApi+"/business/OrderManage/remarkInfo",data)
}
export function orderManageAddRemark(data){//添加备注和旗子
	return request.post(config.baseApi+"/business/OrderManage/addRemark",data)
}
export function orderManageServiceRemarkInfo(data){//订单客服备注信息
	return request.get(config.baseApi+"/business/OrderManage/serviceRemarkInfo",data)
}
export function orderManageAddServiceRemark(data){//添加客服备注
	return request.post(config.baseApi+"/business/OrderManage/addServiceRemark",data)
}
export function orderManageReceipt(data){//确认收货
	return request.post(config.baseApi+"/business/OrderManage/receipt",data)
}
export function orderManagePurchaseCancel(data){//取消采购
	return request.post(config.baseApi+"/business/OrderManage/purchase_cancel",data)
}
export function orderManageOrderCancel(data){//取消订单
	return request.post(config.baseApi+"/business/OrderManage/order_cancel",data)
}
export function getOrderProductList(data){
	return request.post(config.baseApi+"/business/OrderManage/orderProductList",data)
}
export function getRelativeProductList(data){
	return request.post(config.baseApi+"/business/DdProduct/relative_product_list",data)
}
export function ddShopRefreshDecrypt(data){//提额成功更新店铺状态
	return request.get(config.baseApi+"/business/DdShop/refreshDecrypt",data)
}
export function unDecrypt(data){//放弃解密
	return request.get(config.baseApi+"/business/OrderManage/unDecrypt",data)
}
export function reDecrypt(data){//恢复解密
	return request.get(config.baseApi+"/business/OrderManage/reDecrypt",data)
}
export function baseConfig(data){//系统设置
	return request.get(config.baseApi+"/business/User/baseConfig",data)
}
export function checkPrintRecharge(data){//查询充值打单情况
	return request.post(config.baseApi+"/business/User/checkPrintRecharge",data)
}
export function editPrintProduct(data){//编辑打单商品配置
	return request.post(config.baseApi+"/business/User/editPrintProduct",data)
}
export function businessRoleIndex(data){//岗位管理列表
	return request.post(config.baseApi+"/business/System.Businessrole/index",data)
}
export function businessRoleMenus(data){//权限系统获取菜单
	return request.post(config.baseApi+"/business/System.Businessrole/getRoleMenus",data)
}
export function businessRoleAdd(data){//添加岗位
	return request.post(config.baseApi+"/business/System.Businessrole/add",data)
}
export function businessRoleUpdate(data){//更新岗位设置
	return request.post(config.baseApi+"/business/System.Businessrole/update",data)
}
export function businessRoleDel(data){//删除岗位
	return request.post(config.baseApi+"/business/System.Businessrole/delete",data)
}
export function businessRoleInfo(data){//岗位信息
	return request.post(config.baseApi+"/business/System.Businessrole/getUpdateInfo",data)
}
export function businessRoleExt(data){//岗位禁用状态
	return request.post(config.baseApi+"/business/System.Businessrole/updateExt",data)
}
export function agentuserIndex(data){//员工列表
	return request.post(config.baseApi+"/business/System.Agentuser/index",data)
}
export function agentuserFieldList(data){//获取岗位信息
	return request.post(config.baseApi+"/business/System.Agentuser/getFieldList",data)
}
export function agentuserAdd(data){//添加员工
	return request.post(config.baseApi+"/business/System.Agentuser/add",data)
}
export function agentuserDel(data){//删除员工
	return request.post(config.baseApi+"/business/System.Agentuser/delete",data)
}
export function agentuserInfo(data){//查询员工修改信息
	return request.post(config.baseApi+"/business/System.Agentuser/getUpdateInfo",data)
}
export function agentuserUpdate(data){//更新员工
	return request.post(config.baseApi+"/business/System.Agentuser/update",data)
}
export function agentuserResetPwd(data){//重置密码
	return request.post(config.baseApi+"/business/System.Agentuser/resetPwd",data)
}
export function agentuserExt(data){//修改员工显示状态
	return request.post(config.baseApi+"/business/System.Agentuser/updateExt",data)
}
export function productDicker(data){//商品谈价
	return request.post(config.baseApi+"/business/DdProduct/productdicker",data)
}
export function productDickerHistory(data){//商品谈价历史
	return request.get(config.baseApi+"/business/DdProduct/productdickerHistory",data)
}
export function collectList(data){//档口收藏列表
	return request.get(config.baseApi+"/business/Collect/list",data)
}
export function collectCancel(data){//档口收藏取消
	return request.post(config.baseApi+"/business/Collect/collectCancel",data)
}
export function packageList(data){//获取可充值的套餐
	return request.post(config.baseApi+"/business/quota/package_list",data)
}
export function packageRecharge(data){//充值
	return request.post(config.baseApi+"/business/quota/recharge",data)
}
export function packageCheckOrder(data){//充值判断
	return request.post(config.baseApi+"/business/quota/check_order",data)
}
export function quotaList(data){//充值记录
	return request.post(config.baseApi+"/business/quota/list",data)
}