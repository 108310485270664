import config from "../../assets/js/conf/config";
import request from "../../assets/js/utils/request";

/**
 * 进货车
 */
export function addAllProduct(data){//一键加入进货车列表
	return request.get(config.baseApi+"/business/Cart/addAll",data)
}
/**
 * 进货车
 */
export function cartList(data){//获取进货车列表
	return request.get(config.baseApi+"/business/Cart/list",data)
}

export function selectOptions(data){//进货车选中/取消商品
	return request.post(config.baseApi+"/business/Cart/setSelect",data)
}

export function deleteOptions(data){//删除进货车商品
	return request.post(config.baseApi+"/business/Cart/del",data)
}

export function moreSkus(data){//选择更多产品分类
	return request.get(config.baseApi+"/business/Cart/productSku",data)
}

export function submitPH(data){//提交铺货
	return request.post(config.baseApi+"/business/Cart/puhuo",data)
}

export function submitPH2(data){//一键重铺
	return request.post(config.baseApi+"/business/Cart/puhuo2",data)
}

