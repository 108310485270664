import axios from 'axios'
import qs from 'qs'
import md5 from 'js-md5'
import VueCookies from 'vue-cookies'
var CryptoJS = require('crypto-js');

const SAFE_TOKEN = 'A9jkL8ya';

function timest() {
	var tmp = Date.parse(new Date()).toString();
	tmp = tmp.substr(0, 10);
	return tmp;
}

// 设置验证数据
function setParams(data) {
	var params = {}
	for (let key in data) {
		if (data[key] !== '' && data[key] !== undefined && data[key] !== null && data[key] !== 'undefined') {
			params[key] = data[key]
		}
	}
	// var str_client = 1481029047;
	var str_client = timest();
	str_client = str_client.toString();
	var str_server = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA1(str_client, SAFE_TOKEN));
	params['int_client'] = str_client
	params['str_server'] = str_server
	params['auth'] = localStorage['auth']
	var str_param = ksort(params)
	// str_param ="int_client=1481029047&phone=13873581956&str_server=zLOSzOc5tVlDXL+JjPlIDg+5Qkc="
	// console.log(str_param)
	var str_sign = md5(md5(str_param) + SAFE_TOKEN)
	params['sign'] = str_sign
	return params
}

function ksort(params) { //排序的函数
	let keys = Object.keys(params).sort();
	let newParams = {};
	keys.forEach((key) => {
		newParams[key] = params[key];
	});
	var str_param = '';
	for (let key in newParams) {
		str_param += '&'
		str_param += key
		str_param += '='
		str_param += params[key]
	}
	str_param = str_param.substr(1);
	return str_param;
}


// post请求
function _post(url, params = {}, method = 'post') {
	let data = setParams(params)
	return new Promise((resolve, reject) => {
		console.log(555,url)
		axios({
				url: url,
				method: method,
				headers: {},
				data: qs.stringify(data)
			})
			.then(res => {
				//成功
				resolve(res.data)
			})
			.catch(res => {
				//失败
				reject(res)
			})

	})
}
// get请求
function _get(url, params = {}, method = 'get') {
	let data = setParams(params)
	console.log(555,url)
	return new Promise((resolve, reject) => {
		axios({
				url: url,
				method: method,
				headers: {},
				params: data
			})
			.then(res => {
				//成功
				resolve(res.data)
			})
			.catch(res => {
				//失败
				reject(res)
			})

	})
}


export default {
	get: _get,
	post: _post
}
