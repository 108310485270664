import {
	celveData,
	saveCelve,
	delCelve,
	setAvatar,
	changePhone,
	changePwd,
	changeRemind,
	changeDecrypt,
	changeAutoPurchase,
	moneyData,
	paymentCode,
	checkRecharge,
	tixianInfo,
	tixianData,
	walletData,
	extendUrl,
	extendList,
	goodsFilterData,
	goodsList,
	batOnSale,
	batOffSale,
	productDel,
	batProductEdit,
	ddShopList,
	ddShopDel,
	goodsRelativeInfoData,
	goodsConfigData,
	submitRelative,
	similarGoodsData,
	syncProduct,
	orderManageList,
	orderManageAddressInfo,
	orderManageShopList,
	editSyncOrderQueue,
	orderManageDetail,
	orderManageFilterData,
	orderManageBatPurchase,
	orderManageBatAbnormal,
	orderManageChangeSku,
	orderManageCheckoutChangeSku,
	orderManageRemarkInfo,
	orderManageAddRemark,
	orderManageServiceRemarkInfo,
	orderManageAddServiceRemark,
	orderManageReceipt,
	orderManagePurchaseCancel,
	ddShopRefreshDecrypt,
	decryptLog,
	unDecrypt,
	reDecrypt,
	baseConfig,
	checkPrintRecharge,
	changeAutoPrint,
	editPrintProduct,
	businessRoleIndex,
	businessRoleMenus,
	businessRoleAdd,
	businessRoleUpdate,
	businessRoleDel,
	businessRoleInfo,
	businessRoleExt,
	agentuserIndex,
	agentuserFieldList,
	agentuserAdd,
	agentuserDel,
	agentuserInfo,
	agentuserUpdate,
	agentuserResetPwd,
	agentuserExt,
	productEditUrl,
	orderManageCutList,
	orderManageBatIntercept,
	goodsListSecond,
	saleConfigSave,
	productDicker,
	productDickerHistory,
	batProductDel,
	collectCancel,
	collectList,
	checkoutSkuRelative,
	goodsSkuChangeLog,
	getOrderProductList,
	orderManageOrderCancel,
	shopEditUrl,
	getRelativeProductList,
	packageList, packageRecharge, packageCheckOrder, quotaList
} from "../../../api/main/ucenter.js";
import {userinfoData} from "../../../api/main/login.js";
let modules = {
	namespaced: true,
	state: {


	},
	mutations: {
		
	},
	actions: {
		getUserinfoData(conText, payload){//获取个人信息
			userinfoData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getCelveData(conText, payload){//获取上架策略数据
			celveData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opDelCelve(conText, payload){//删除上架策略数据
			delCelve(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		saveCelveData(conText, payload){//保存上架策略数据
			saveCelve(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		changeAvatar(conText, payload){//更换头像
			setAvatar(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doChangePhone(conText, payload){//换绑手机号
			changePhone(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doChangePwd(conText, payload){//更改密码
			changePwd(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doChangeRemind(conText, payload){//接收公众号提醒
			changeRemind(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doChangeDecrypt(conText, payload){//开启自动解密
			changeDecrypt(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doChangeAutoPurchase(conText, payload){//开启自动采购
			changeAutoPurchase(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doChangeAutoPrint(conText, payload){//开启自动打单
			changeAutoPrint(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getDecryptLog(conText, payload){//解密日志
			decryptLog(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		
		getMoneyData(conText, payload){//获取钱包金额信息
			moneyData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getPaymentCode(conText, payload){//获取支付二维码
			paymentCode(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getCheckRecharge(conText, payload){//查询充值情况
			checkRecharge(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getTixianInfo(conText, payload){//获取提现信息
			tixianInfo(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doTixian(conText, payload){//发起提现
			tixianData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getWallteData(conText, payload){//获取钱包记录流水
			walletData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getExtendUrl(conText, payload){//获取推广中心链接
			extendUrl(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getExtendList(conText, payload){//获取推广中心合作列表
			extendList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		//我的抖店
		getDdShopList(conText, payload){//抖店列表
			ddShopList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opDdShopDel(conText, payload){//删除抖店
			ddShopDel(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		// 我的商品
		getGoodsFilterData(conText, payload){//获取我的商品筛选数据
			goodsFilterData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getGoodsList(conText, payload){//获取我的商品列表
			goodsList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opBatOnSale(conText, payload){//批量上架商品
			batOnSale(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opBatOffSale(conText, payload){//批量下架商品
			batOffSale(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opProductDel(conText, payload){//删除商品
			productDel(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opBatProductEdit(conText, payload){//商品批量改价
			batProductEdit(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opBatProductDel(conText, payload){//删除商品
			batProductDel(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
        getProductEditUrl(conText, payload){//删除商品
			productEditUrl(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getShopEditUrl(conText, payload){//删除商品
			shopEditUrl(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		/**
		 * 关联商品 
		 */
		GetGoodsRelativeInfoData(conText, payload){//获取关联商品信息
			goodsRelativeInfoData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		GetGoodsConfigData(conText, payload){//获取关联商品信息
			goodsConfigData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doSubmitRelative(conText, payload){//关联商品信息
			submitRelative(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opCheckoutSkuRelative(conText, payload){//关联商品检出变动订单数、金额
			checkoutSkuRelative(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getSimilarGoodsData(conText, payload){//搜索同款商品
			similarGoodsData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getSkuChangeLog(conText, payload){// 商品sku变更日志
			goodsSkuChangeLog(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opSyncProduct(conText, payload){//一键同步商品
			syncProduct(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getOrderManageList(conText, payload){//获取我的订单列表
			orderManageList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getOrderManageAddressInfo(conText, payload){//查看收货人信息
			orderManageAddressInfo(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getOrderManageShopList(conText, payload){//查看所有店铺
			orderManageShopList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		postEditSyncOrderQueue(conText, payload){//编辑提交同步历史订单
			editSyncOrderQueue(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getOrderManageDetail(conText, payload){//订单详情
			orderManageDetail(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getOrderManageFilterData(conText, payload){//订单筛选数据
			orderManageFilterData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opOrderManageBatPurchase(conText, payload){//手动批量采购
			orderManageBatPurchase(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opOrderManageBatAbnormal(conText, payload){//批量标记异常
			orderManageBatAbnormal(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doOrderManageChangeSku(conText, payload){//关联商品
			orderManageChangeSku(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opOrderManageCheckoutChangeSku(conText, payload){//关联商品
			orderManageCheckoutChangeSku(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getOrderManageRemarkInfo(conText, payload){//订单标记备注信息
			orderManageRemarkInfo(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opOrderManageAddRemark(conText, payload){//添加备注和旗子
			orderManageAddRemark(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getOrderManageServiceRemarkInfo(conText, payload){//订单客服备注信息
			orderManageServiceRemarkInfo(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opOrderManageAddServiceRemark(conText, payload){//添加客服备注
			orderManageAddServiceRemark(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opOrderManageReceipt(conText, payload){//确认收货
			orderManageReceipt(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opOrderManagePurchaseCancel(conText, payload){//取消采购
			orderManagePurchaseCancel(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},

		opOrderManageOrderCancel(conText, payload){//取消采购
			orderManageOrderCancel(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},

		opDdShopRefreshDecrypt(conText, payload){//提额成功更新店铺状态
			ddShopRefreshDecrypt(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opUnDecrypt(conText, payload){//放弃解密
			unDecrypt(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getOrderProductList(conText, payload){
			getOrderProductList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getRelativeProductList(conText, payload){
			getRelativeProductList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opReDecrypt(conText, payload){//恢复解密
			reDecrypt(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getBaseConfig(conText, payload){//基础设置
			baseConfig(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getCheckPrintRecharge(conText, payload){//查询充值打单情况
			checkPrintRecharge(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opEditPrintProduct(conText, payload){//编辑打单商品配置
			editPrintProduct(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getBusinessRoleIndex(conText, payload){//岗位管理列表
			businessRoleIndex(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getBusinessRoleMenus(conText, payload){//权限系统获取菜单
			businessRoleMenus(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opBusinessRoleAdd(conText, payload){//添加岗位
			businessRoleAdd(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opBusinessRoleUpdate(conText, payload){//更新岗位设置
			businessRoleUpdate(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opBusinessRoleDel(conText, payload){//删除岗位
			businessRoleDel(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getBusinessRoleInfo(conText, payload){//岗位信息
			businessRoleInfo(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opBusinessRoleExt(conText, payload){//岗位禁用状态
			businessRoleExt(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getAgentuserIndex(conText, payload){//员工列表
			agentuserIndex(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getAgentuserFieldList(conText, payload){//获取岗位信息
			agentuserFieldList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opAgentuserAdd(conText, payload){//添加员工
			agentuserAdd(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opAgentuserDel(conText, payload){//删除员工
			agentuserDel(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getAgentuserInfo(conText, payload){//查询员工修改信息
			agentuserInfo(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opAgentuserUpdate(conText, payload){//更新员工
			agentuserUpdate(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opAgentuserResetPwd(conText, payload){//重置密码
			agentuserResetPwd(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opAgentuserExt(conText, payload){//修改员工显示状态
			agentuserExt(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getOrderManageCutList(conText, payload){//获取我的订单列表
			orderManageCutList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opOrderManageBatIntercept(conText, payload){//批量设置拦截
			orderManageBatIntercept(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getGoodsListSecond(conText, payload){//获取我的商品列表
			goodsListSecond(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opSaveSalesConfig(conText, payload){//保存销量配置
			saleConfigSave(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opProductDicker(conText, payload){//商品谈价
			productDicker(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getProductDickerHistory(conText, payload){//获取商品谈价历史
			productDickerHistory(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getCollectList(conText, payload){//档口收藏列表
			collectList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doCollectCancel(conText, payload){//档口收藏列表
			collectCancel(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getPackageList(conText, payload){//档口收藏列表
			packageList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		setpackageRecharge(conText, payload){//档口收藏列表
			packageRecharge(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getpackageCheckOrder(conText, payload){//档口收藏列表
			packageCheckOrder(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getQuotaList(conText, payload){//档口收藏列表
			quotaList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
	}

}
export default modules;
